import React from 'react'

let blogposts = [
    {
        title: "Winter Quarter: Classes",
        date: "03/20/22",
        tags: ["CS", "Classes"],
        content:
            <div>
                <figure className="right">
                    <img src={require("./BImages/exec_edu_building.jpeg")} alt="Kyle Pu in a lecture room in UCLA's Executive Education Building" />
                    <figcaption>Lecturing my imaginary students</figcaption>
                </figure>
                Winter 2022 is officially over! It's been a fun quarter seeing everyone get active on campus again.
                <br /><br />
                My thoughts on classes this quarter:
                <ul>
                    <li>CS 111 (Operating Systems): Cool, learned a lot about the software-hardware boundary. My favorite topics include CPU and memory virtualization.</li>
                    <li>Musicology 68 (The Beatles): Great class to discover new music, explore the lives of The Beatles, and understand how music has changed because of them.</li>
                    <li>Life Science 7B (Genetics, Evolution, and Ecology): Topics are very broad, a lot of reading. Ecology was surprisingly interesting. You learn a bit about species interactions and how/why environments change.</li>
                    <li>Stats 100A (Intro to Probability): Fun class based on poker.</li>
                </ul>
                These courses count towards a CS degree?!? Yes! Music is a GE and LS7B counts towards my <a href="https://www.seasoasa.ucla.edu/undergraduate-technical-breadth-area-tba/">tech breadth</a>.
            </div>
    },

    {
        title: "Winter Quarter: Adventures",
        date: "03/21/22",
        tags: ["LA", "Travel", "Adventure"],
        content:
            <div>
                <figure className="right">
                    <img src={require("./BImages/santa_monica_pier.jpeg")} alt="Pacific Park entrance at Santa Monica Pier" />
                    <figcaption>Santa Monica Pier</figcaption>
                </figure>
                What's the best part of going to school in LA? Going outside! From Venice Beach to Santa Monica and Beverly Hills, beautiful destinations are within miles of our classrooms.
                <br /><br />
                My finals ended on Monday of finals week this quarter (virtually a two week spring break for me!), so I had the chance to go out the Tuesday after. My adventures began in Venice Beach. Even on a Tuesday morning, the beach and the 2 mile walk along the ocean to Santa Monica Pier was filled with bicyclists, skaters, and a few street performers.
                <br /><br />
                Santa Monica Pier's ferris wheel was easy to spot from miles away, even through a bit of fog. It was easy to gauge our distance by seeing how much bigger it looked each time we checked. At $11 per ride, riding the ferris wheel was no cheap journey. But the beauty of the chill ocean breeze, bright light reflecting off the endless ocean waters, and the palm trees and high-rises of LA were a sight to see. "Ah, I really am in LA."
                <br /><br />
                UCLA is known for being close to the 3 B's: Beverly Hills, Bel-Air, and Brentwood (I honestly never heard of Brentwood before coming here. I had to search up "the 3 B's near UCLA" to find out what the third B was). Beverly Hills is just a 10-15 minute bus ride away from Westwood, our beloved college town. The Beverly Gardens Park was quite underwhelming, featured in a garden smaller than the Botanical Gardens on campus. But Rodeo Drive lived up to its hype. The fancy glass buildings of designer stores, a golden Rolls Royce parked along the road, and fancily-dressed people walking all around made Beverly Hills what social media makes it out to be.
                <figure>
                    <img src={require("./BImages/rodeo_drive.jpeg")} alt="Red Life is Beautiful cursive sign" />
                    <figcaption>Life is Beautiful sign on Rodeo Drive</figcaption>
                </figure>
            </div>
    },

    {
        title: "LA Metro Bikes Appreciation Post",
        date: "03/21/22",
        tags: ["LA", "Travel", "Getting-Active"],
        content:
            <div>
                Yesterday was Kyle-Moves-Back-For-Spring-Break-Eve. I had a couple hours left of the afternoon before my best friend, a local, drove home. So we decided to mess around and ride a Bird scooter around campus. The thing is the people over at Bird have gotten pretty smart about where the Bird is allowed to accelerate. Walking zones cover campus where the Bird scooter becomes a primitive scooter scooter. No racing allowed!
                <br /><br />
                While we were riding around the back of Pauley Pavilion, we noticed a rack of LA Metro Bikes sitting near the entrance to Luskin. At just $3 per hour, they're a great (and much cheaper) alternative to scooters. So, we took them out for a spin.
                <br></br>
                One thing that won't stop amazing me about UCLA's campus is how many hidden spots litter campus. A couple highlights of our ride include a cafe-style seating area behind Anderson and the Executive Education building to a peaceful and green downhill slope from behind YRL (the Young Research Library) to the edge of campus bordering the soccer field across Fowler.
                <br /><br />
                I love biking. LA Metro Bikes will see my business again. 'Till Spring Quarter!
            </div>
    },

    {
        title: "Spring '22: First Day",
        date: "03/28/22",
        tags: ["Classes"],
        content:
            <div>
                <figure className="left">
                    <img src={require("./BImages/powell_spring22.jpeg")} alt="Powell Library in the rain" />
                    <figcaption>Powell Library around 10 AM today</figcaption>
                </figure>
                It's the first day of spring quarter 2022! I was excited for the return to LA because of the warmth and sunshine I've come to expect. Instead, we were greeted with a downpour so intense that strong streams flowed down the sides of Bruinwalk.
                <br /><br />
                I never check the weather forecast before heading out, so I had no choice but to get soaked on my walk back after morning class. The good news is that the sudden storm awakened all of us after our first day 8 AM.
                <br /><br />
                Had an overall solid day. Excited for my classes this quarter, definitely gonna learn a lot of useful things.
            </div>
    },

    {
        title: "LA Hacks 2022: Friend in Need",
        date: "04/20/22",
        tags: ["CS", "Hackathons"],
        content:
            <div>
                LA Hacks, what a crazy ride!
                <br /><br />
                I went into LA Hacks, my second hackathon here at UCLA, much in the same situation as going into Hack on the Hill - with a team figured out but no idea of what we were going to do. But, that all quickly fell apart.

                <figure className="right">
                    <img src={require("./BImages/LAHacks22.jpg")} alt="Our table at LA Hacks 2022" />
                    <figcaption>Lucky Table 8</figcaption>
                </figure>

                <br /><br />

                LA Hacks is on the weekend before Week 4. Truly a far from ideal time considering midterms start as soon as Week 3 in the quarter system. On the days leading up to LA Hacks, I reached out to my team and found out no one was able to come anymore.
                <br /><br />
                Luckily, a good friend of mine was still looking for a team, so we partnered together but decided to take LA Hacks lightly - like an event to spearhead a cool idea while also doing homework and worrying about project implementation later.
                <br /><br />
                Friday night rolled around and in the hours leading up to the opening ceremony, my friend and I realized just how serious people were taking LA Hacks. Companies and sponsors lined the walls of Ackerman's Grand Ballroom, and teams were all brainstorming and setting up shop to seriously build a project in the 36 hours given to us.
                <br /><br />
                So, naturally, we felt a bit scared. We decided to use the Slack forum LA Hacks put on to find more team members (max team size being 5 people), but found no one in person. That's where we got lucky.
                <br /><br />

                <figure className="left">
                    <img src={require("./BImages/LAHacks_NightTime.jpeg")} alt="Bruin Plaza at 5 AM" className="Bigger" />
                    <figcaption>Bruin Plaza at 5 AM</figcaption>
                </figure>

                <br />
                We were sitting at Table 8 (hand-chosen by yours truly for the best of luck - we were the first people there), and I recognized a familiar face sitting several tables behind us. I didn't know his name, but I remembered him because he sat diagonally across the table from our team in Hack on the Hill.
                <br /><br />
                My friend and I went up to him and eventually decided to team up with him and his friend, who was bringing on her friend. Voila! A team of 5!
                <br /><br />
                "Now I don't feel bad about taking all this free stuff because we're actually doing something." My friend was right! We no longer had an excuse to lie around and do our homework during LA Hacks anymore.
                <br /><br />
                Fast-forward 24-ish hours and it's 5:30 AM. I'm just getting in bed because our team spent the last 4 hours polishing features of Friend in Need, a web app for requesting help from people around you on campus (think when you forgot your charger but you're all the way in Powell).
                <br /><br />
                The 3 first-years on our team pulled through with amazing graphic design and a smooth backend built on Firebase, and my friend and I brought the design concepts into Internet reality. We deployed the website, so go <a href="https://celebilaw.github.io/lahacks22">check it out!</a>
                <br /><br />
                Though we didn't win anything this time, we sure did learn a lot about hackathons. And more importantly, we had a blast. 'Till next time LA Hacks.
            </div>
    },

    {
        title: "LA Hacks 2022: Typing Tournament",
        date: "04/20/2022",
        tags: ["CS", "Hackathons"],
        content:
            <div>
                <figure className="right">
                    <img src={require("./BImages/Razer.jpeg")} alt="Razer Mechanical Keyboard" />
                    <figcaption>My First Mechanical Keyboard!</figcaption>
                </figure>
                Hacking officially began at 10 PM on Friday, April 15th. There were several fun activities throughout LA Hacks for us, one of them being a typing tournament.
                <br /><br />
                I hesitated about joining the typing tournament since I assumed typing is a relatively stable activity. Stable in the sense that a person's WPM won't change much from run to run. So, I thought a tournament didn't make much sense for something like typing and also that there had to be people who could type like 200 WPM at LA Hacks.
                <br /><br />
                My team (and my mom) encouraged me to go, so I did for fun. Now, this is mine :)
                <br /><br />
                It's a pretty loud keyboard, so I asked my roommate for his honest opinion. I've committed to only using this keyboard when he's not in the room now 🤣
            </div>
    },

    {
        title: "Tennis",
        date: "06/09/2022",
        tags: ["Tennis", "Getting-Active"],
        content:
            <div>
                Well it's been a while since you've seen me, hasn't it! I'll work on getting better with consistent blog posts :D
                <br /><br />
                I took a Beginner's Tennis class with UCLA's Sunset Rec this quarter. I've been wanting to find a way to stay active and have fun, and tennis seemed like the ideal sport since we have LATC right on campus, and so many people on my floor that play!
                <br /><br />
                I went through the first half of the quarter not playing much more tennis than the hour we spent every Tuesday afternoon with our tennis teacher. Learned the basic strokes, forehand and backhand, and a couple volley techniques. Since we had 7 people in the class (maybe around 5 or 6 average showed up each time), we didn't get all that much time to actually play each week.
                <br /><br />
                I got a few friends to bring tennis rackets to school, so I started really getting into tennis after Week 5. I had no idea we could even reserve the courts over at Sunset Rec until someone from my tennis class, who works there, told me! That gave me a LOT more motivation to play more, since it's so much more accessible knowing we can reserve courts instead of waiting endlessly at LATC for people to leave.
                <br /><br />
                I played a lot this quarter. I'd say at least twice a week for about an hour and a half for the past several weeks. I wouldn't say I'm good at tennis yet, but I think I'm at the point where I know where I need to put in more practice to improve (power in my forehand shots and better form with my backhand, where I send the ball flying up high over the net far too much haha).
                <br /><br />
                It's always exciting heading to a tennis court, and I hope to make it a bigger part of my routine next quarter in the Fall. I'm planning on joining IM's with a friend next quarter, which should be a fun way to get more practice in!
            </div>
    },

    {
        title: "Spring Finals",
        date: "06/09/2022",
        tags: ["CS", "Classes"],
        content:
            <div>
                It's finals week! Spring Quarter 2022 was CS 180 (Algorithms and Complexity), CS 143 (Data Management Systems), CS M152A (Digital Design Lab, think FPGAs and Verilog), and Stats 10 (Life Science GE). I went in feeling pretty scared because I expected 180 to take up most of my time, and it sure did. But I also learned all sorts of problem solving techniques and algorithms that've  felt like some of the most useful content I've learned so far (and some of the most stressful homework assignments because they seem so short - 6 questions a week - but take so long!).
                <br /><br />
                CS 180's final was this Monday. 6 questions, 2 hours and 45 minutes. I read all my review notes from the quarter, a set of notes I write after every class re-doing problems solved during lecture (Prof. Sarrafzadeh's lecture style is very much example-based). We weren't allowed notes, but I think the test reflected what we learned in class well.
                <br /><br />
                CS 143 is probably one of the most dense classes I've taken. 80 slides per lecture, and 18 lectures throughout the quarter (for the spring quarter). SQL is only a tiny part of the course, which broadly covers database theory and how to design/manage different types of data systems depending on use case. It's a practical class that can teach you how to deal with large quantities of data, but it also gets heavy at times with database theory that's fundamental in DB land. The final felt rushed, even with 3 hours, because there's such a wide-range of topics in the class. Definitely very relevant and a great CS elective on a different part of CS that's not really discussed in the lower-div courses. It's my first CS elective - glad I took it, and glad it's over!
                <br /><br />
                CS M152A doesn't have a final. Instead, we have a final project where we use Verilog, a hardware description language, to build our own creative project on FPGAs (Field-Programmable Gate Arrays). My lambaste and I wrote Connect 4 on FPGAs, a pretty intense project encoding token drop animations, and interactive player controls, using solely VGA for video output and the FPGA for player input. I didn't expect to like M152A much, but I come out of it with respect for FPGAs and all the hardware people all there. The stuff is not easy!
                <br /><br />
                And Stats 10. Ah, Stats 10. I took AP Stats in high school, so Stats 10 is mostly a review of the material (possibly even a little less material). It satisfies the Life Science GE so it counts towards my degree. I took it mostly to have a chiller class to balance out the rest of the quarter, and it definitely ended up being a class where I could listen in and work on other classes during lecture haha. I feel like most people take this class to sort of balance out their schedules, since no one really shows up to discussion. I was the only person to show up to in-person discussion on the last day (a few people in the Zoom since we were hybrid), so it was a bit sad but understandable.
            </div>
    },


    {
        title: "Undie Run",
        date: "06/09/2022",
        tags: ["Getting-Active"],
        content:
            <div>
                Who would've known running through my campus almost naked would be on my list of things done at college. Last night, or today at midnight I should say, we had our quarterly undie run! The undie run is probably one of the craziest, rowdiest, and most insane traditions at UCLA. Easily THOUSANDS of kids gather on a street outside frat row, all in their undies, yelling and running under the bridge, past the engineering school, through Bruin Plaza, and up the steps to Royce. Police cars lined the area, which are coned off to block traffic and to guide students as we all storm the streets.
                <br /><br />
                It was a great way to celebrate being done with finals and finishing off a school year. The energy was truly something different - everyone running, hitting the signs on the street, people with their grad gear to celebrate their last undie run, and everyone just all crowded up, celebrating finals week together. The midnight scream, another tradition where everyone yells out their window every night of finals week, also happened during the run.
                <br /><br />
                It was nice to do something so crazy, and it was fun to do it in the cold of the night. Took another shower when we got back because we felt a bit dirty, but it was well worth the experience. Definitely a night to remember.
            </div>
    },

    {
        title: "Move Out and Looking Forward to Summer '22",
        date: "06/09/2022",
        tags: ["Milestone"],
        content:
            <div>
                An empty desk, a mattress with no sheets, and a blank wall canvas. It looked just the way it looked when we moved in just earlier this year. We had done it - my family and I had somehow successfully gathered and cleaned all my crap. My second year is done and over.
                <br /><br />
                My family came down to help me move out today. I gave them a tour of campus, showing them around to some of my favorite spots. From Mong Auditorium in Engineering VI to the Court of Sciences, and classrooms in Boelter, Young Hall, and Dodd, we all got to experience some of the classroom vibes of UCLA. And of COURSE I showed them Royce, and the inside of Powell & YRL (thankful all people are allowed there now)! It was great showing them the places I spend so much time in these days.
                <br /><br />
                We packed up, got meal tickets using the remainder of my swipes, and had a nice dinner of BBQ Brisket Sandwiches from BCafe. We all enjoyed it, such good stuff. Happy to have a nice takeout place to bring them to in a time when the dining halls still aren't open to anyone we want to bring in (not even off-campus students)!
                <br /><br />
                Very bittersweet saying goodbye to the people on my floor. I feel like I couldn't have had a better floor. Mostly social people, all very kind, and all very fun to be around. I'm gonna miss my neighbors, my floormates, and the people I got to see almost every day this quarter. It was great to see The Hill so alive again, with people streaming all over the place, elevators absolutely packed, and lines just to return the moving cars. But it's also sad that we're all dispersing back around the world, some of us coming back in due time, and others moving on to take on their new chapters.
                <br /><br />
                And here I am in the middle of all this, now at the airport. Sad I can't be home, but looking forward to all there is this summer. I'm going on an adventure, and I'm excited to tell you all about it in the coming days. Till then! Au revoir, my beloved blog.
            </div>
    },
    {
        title: "Summer '22: MSK in NYC",
        date: "08/16/2022",
        tags: ["Summer", "NYC"],
        content:
            <div>
                Wow what an adventure it's been! Just got back home from what's probably the most fun summer of my life. I spent the last couple months in New York City doing comp bio research at Memorial Sloan Kettering Cancer Center (MSK). Days after landing back home, I still can't believe it's over. I look back on the pictures and feel sad we're not there anymore. But I'm unbelievably grateful for the memories we created.
                <br /><br />
                I remember landing in NYC, for the first time truly by myself. Literally knowing not a single person around me. Feeling like a stranger to the environment, but also feeling in awe of how grand the city was.
                <br /><br />
                Getting to know the people of CBSP (MSK's Computational Biology Summer Program) in our common housing areas gave me the community I didn't realize I wanted so desperately. It felt a bit like college - you meet people and don't know just how much they'll affect your life in a short couple months.
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/Pro.jpeg")} alt="Pro" />
                    <figcaption>A Professional In<br />Pretending to be Professional</figcaption>
                </figure>
                We spent our initial weeks getting to know the basic facts about each other and exploring Manhattan through food. Getting dinners together at places within walking or subway'ing distance showed us around Manhattan.
                <br /><br />
                We spent weekends together going places! From Governor's Island to Staten Island, we slowly but surely touched ground in all 5 boroughs of NYC. We went to close tourist traps like The Highline and ventured far to beautiful spots like The Bronx Zoo. You wouldn't expect to see so much natural beauty in a city filled with buildings that touch the clouds (literally!).
                <br /><br />
                Summer was full of surprises. My gastrointestinal shortcomings became a bonding topic. One of my new besties turns out to have lived in the same, exact dorm room my brother did just a couple years before. I serendipitously bumped into my 2nd year RA at the Queens Night Market!
                <br /><br />
                It might seem like my lasting impressions of NYC have little to do with the actual work I was able to do. But I had a good time from an academic viewpoint too! I worked in an office space that could be mistaken for a call center to outsiders. But to me, the office was home to a group of scientists, experts in computation, biology, and statistics, and teams that inspired me.
                <br /><br />
                The undergrads, grad students, researchers, and PI's I got to be around are some of the smartest people I've met. It's encouraging to see and hear about the vibrant community we have tackling problems in cancer.
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/Columbia.jpeg")} alt="Columbia" />
                    <figcaption>Core Memory @ Columbia</figcaption>
                </figure>
                I worked on applying a previously developed dose-response model in a new context for patient-derived xenograft data. I had the mentorship of a brilliant computer scientist-statistician who helped me think about and approach the problems. I think I learned quite a lot in the 10 short weeks at MSK, from both the work and the seminars, journal clubs, and talks I got to participate in.
                <br /><br />
                MSK really was an ideal environment to do research in this past summer. A place where most everyone seems driven, curious, and happy. And a place where once I got off work hours, I literally did not work anymore :D (besides the second-to-last week where we all stayed up late together working on last-minute posters ahaha).
                <br /><br />
                For my last two dinners, I went to Udon Lab in KTown and Non Solo Piada on 37th street, both restaurants I went with my roommate and by myself to in the first couple days of arriving in New York. Only this time, I was surrounded by friends. People we'd shared our lives with for the past couple months - people we felt like we've known for an eternity even though we just met this summer. There are some moments in life that feel so satisfyingly full-circle.
                <br /><br />
                <figure>
                    <img src={require("./BImages/Cruise.jpeg")} alt="Cruise" />
                    <figcaption>Besties on a Boat<br />(Definitely Our Own Yacht)</figcaption>
                </figure>
                I'm forever grateful for the opportunity to meet the smartest and coolest people I've gotten to spend time with. I don't know if I loved NYC, or if I loved being in NYC with the people I got to be with. I may never figure that out, but either way, I hope to one day revisit the Big Apple.
                <br /><br />
                P.S. I helped put together a newsletter (credit to Casey and Liz for literally all the designing) to capture our summer moments together. My favorite sections are of course all the entries from CBSP people!!! Check it out <a href="https://www.mskcc.org/sites/default/files/node/163501/document/s-m2022.pdf" target="_blank">here</a>!
            </div>
    },
    {
        title: "Welcome Back to LA",
        date: "09/14/2022",
        tags: ["LA"],
        content:
            <div>
                Well, I'm back at school. I've been here since last Wednesday, but school wasn't officially in session until today (this post written on the 22nd).
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/WelcomeToUCLA.jpeg")} alt="WelcomeToUCLA" />
                    <figcaption>My Brother Writing Berkeley<br />Propaganda on UCLA's Welcome Board</figcaption>
                </figure>
                Moving back to school has been nice. The nervous excitement isn't here anymore - the novelty of campus has already faded. But there's a new form of excitement. Excitement for the familiar faces all around, for the tennis friends within minutes of my dorm, for the parts of LA that await our arrival, and for the reliving of what already feels like the old times (eating at the dining halls, hanging out in Westwood, and walking around campus just enjoying the vibes).
                <br /><br />
                Probably the only thing that bothers me is that I'm far from family now (out of state kids must be laughing at this).
                <br /><br />
                There's plenty of things to look forward to this year. I'm taking cool classes for the most part, and getting more into some new hobbies. Gonna find more time to play tennis, for fun and in IM's, and my IM's partner roped me into a weekly KPop Dance Class that should be fun.
                <br /><br />
                There's more of LA to explore. All the activity over this past summer made me realize I really don't know LA for what it is. Sure it's the city I go to college in, but it really isn't much more than that. My college city is almost arbitrary, irrelevant. I could go to school anywhere and if the education were the exact same, I wouldn't notice a thing. And that's something I'd like to change this year. I wanna get out to see the beaches, the city, the hiking trails, the restaurants, the museums, and all the other places I haven't even thought of yet.
                <br /><br />
                We'll see where this year goes. Will I really fulfill these goals? Only time will tell!
            </div>
    },
    {
        title: "LA Chinatown",
        date: "09/17/2022",
        tags: ["LA", "Chinatown"],
        content:
            <div>
                <figure className="left">
                    <img src={require("./BImages/Chinatown.jpeg")} alt="Chinatown" />
                </figure>
                <figure className="right">
                    <img src={require("./BImages/ChinaGroup.jpeg")} alt="ChinaGroup" />
                    <figcaption></figcaption>
                </figure>
                Have I already started working on my goals?!? I went out with a couple friends to see a new part of LA this weekend - Chinatown! LA is celebrating the Mid-Autumn Moon Festival a week after it actually happened. Not sure why the event takes place a week after the actual festival, but lucky for me since we're in LA now!
                <br /><br />
                The journey to Chinatown is about an hour and a half long from campus, even though it's only 16 miles away. The public transit here isn't that great! If only we could have subways and high speed rails everywhere. The highlight of the commute was driving by USC. The outsides of their campus actually looks pretty nice.
                <br /><br />
                Chinatown was pretty empty except for the big plaza the festival was taking place at. Crowds of people, live music, dance demos, and stations selling everything from meat skewers to dim sum, sugarcane juice, and jewelry. Felt like a night market.
                <br /><br />
                I tried Tanghulu for the first time! We also got some very mid dimsum for dinner. The food definitely wasn't the highlight, unfortunately. What was great was having a little flashback to what life is like in NYC.
                <br /><br />
                Final thoughts? I don't remember much of what Chinatown in SF is like, but if I had to rank them, I'd say NYC > SF > LA in terms of their Chinatowns.
            </div>
    },
    {
        title: "First Day, Third Year",
        date: "09/22/2022",
        tags: ["Classes", "Game Day"],
        content:
            <div>
                It doesn't feel like classes have really started.
                <br /><br />
                My first class of the year is CS M146, Intro to Machine Learning. It's held in the Northwest Auditorium, which is right here on The Hill. Feels so weird to walk across the street to get to the classroom. The vibes are kind of weird, but that's expected when you walk into a room full of nerds.
                <br /><br />
                Most of class was just a high-level overview of the field of ML. Pretty chill and not much substance yet. Our professor warned us that this will be a heavy class though haha.
                <br /><br />
                I had one other class, Social Change (Public Affairs 10). I'm taking it as a chill GE, and it sure seemed pretty chill. We got right into things, and it seems that the class will mostly just be a discussion of social problems.
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/Pauley.jpeg")} alt="Pauley" />
                    <figcaption>Biggest Crowd Turnout in UCLA Women's<br /> Volleyball History</figcaption>
                </figure>
                A lot of the discussion today felt superficial and politically charged, which I'm honestly not a big fan of. But it's good to hear what other people think, and most of us seem to have an open mind, so it should be a good experience to get even more open minded haha.
                <br /><br />
                Jeff mentioned that the women's volleyball game against USC was tonight, so we decided to go. My first sporting event against SC! The line was pretty quick, and it was exciting to get settled into our seats in Pauley.
                <br /><br />
                Gayathri was pumped since she loves volleyball. I honestly thought it was pretty boring, but it was fun to be with friends and to just enjoy the vibes. The crowd boos so loudly whenever a USC person is up serving haha.
                <br /><br />
                USC won the first two sets and won the third (ending the match), but UCLA appealed the last point and the judges awarded the point to UCLA, setting off a long battle to win the third. UCLA came out on top, which got the crowd absolutely electric. It was insanely loud in there, everyone jumping up and down and rushing back to their seats (since we were leaving, thinking the match was over).
                <br /><br />
                USC eventually won the fourth set, but I walked away inspired at how hard LA fought. Pretty relaxing first day!
            </div>
    },
    {
        title: "Fall '22",
        date: "12/09/2022",
        tags: ["Classes"],
        content:
            <div>
                Done with finals! It's winter break! It hasn't really hit that a whole quarter has gone by just like that. This quarter has easily felt like the shortest quarter so far. A lot happened - I finally got covid (and quarantined for 10 whole days), played tennis double's IM's,  caught a bad cold for a couple weeks, did some dancing, and got through another set of classes.
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/Fall22.jpeg")} alt="Walking Down The Hill" />
                    <figcaption>Walking Thru Fall '22</figcaption>
                </figure>
                This quarter, I took CS Theory, Machine Learning, a comp bio seminar, and Social Change. Between reviewing lectures and doing problem sets, the workload this quarter was pretty smooth. A lot I'll take with me and probably a lot I'll soon forget :D
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/WestwoodFall22.jpeg")} alt="Westwood" />
                    <figcaption>The Beginnings of<br />Winter in Westwood</figcaption>
                </figure>
                Finals were interesting this quarter. Harder than expected, but designed in a way where I feel like I start to see how concepts from the class come together. And I can't complain. I only had 2 finals (CS Theory and ML)!
                <br /><br />
                I'm excited to take a long break. This year's winter break is an entire month! Not sure what I'll do with the time yet, but for now I'll look forward to a nice reset!
            </div>
    },
    {
        title: "Doubles Tennis IM's, Fall '22",
        date: "12/09/2022",
        tags: ["Tennis"],
        content:
            <div>
                We got destroyed. My friend and I joined tennis IM's this quarter since I wanted to try out a bit of competitive tennis. IM's is arranged in a way where you play 4 matches and make it to playoffs if you win at least 2.
                <br /><br />
                I learned a lot after the first match. For one, I can't serve. Second, I don't have good control of the ball. And third, I get stage fright playing tennis even though LATC is 99% empty during IM matches (I just lose the little consistency I have outside of matches).
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/LATC.jpeg")} alt="LATC" />
                    <figcaption>LATC is on Our Campus!</figcaption>
                </figure>
                We lost our second match the week after our first. But we were happy with the progress we were making. At least each game wasn't a complete blowout, and we made some cool plays here and there. My partner played varsity during high school, so he carried with some powerful winners.
                <br /><br />
                Our third week we finally won a match (only by one game lol. In IM's, you only play one set and we decided to just keep playing until the next time slot's teams came)! Looking at the stats of the next team we were facing the week after, we didn't think we would win. And we didn't haha. And that's how our IM's career came to a close after just 4 weeks.
                <br /><br />
                It looks like going pro isn't an option for me anymore. But I had fun and get a free workout out of it, so I'll definitely be back on the courts whenever I can. Just not competitively.
            </div>
    },
    {
        title: "KPop Dancing",
        date: "12/09/2022",
        tags: ["Dancing"],
        content:
            <div>
                I'm not a dancer. I've never seriously danced, didn't have much of an interest in it, and didn't think it would be part of my college journey. But when my friend asked me if I'd be down to do a KPop dance class with him, there wasn't a good reason to say no! Getting dumped into a dance class seemed like a scary but fun way to get into something new, and I love trying new things.
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/Dancing.png")} alt="Dancing" />
                    <figcaption>Looks Like I Have<br />To Take a Dump</figcaption>
                </figure>
                The instructor actually danced with my friend's brother back in their undergrad days, so we were already at a clear advantage (just kidding there's no grades thankfully). We learned the choreo to the chorus of a new song each week, so there was some nice variety. Dances from boy groups, girl groups, groups I knew, and groups I've never heard of.
                <br /><br />
                It's kind of awkward, but it's probably like that for everyone. We're mostly all there as people who've never danced before. The choreo each week takes up probably not even half a minute, but we always spend the entire hour and 15 minutes learning. It's a slow and steady process where we're learning new moves, memorizing the sequence of steps, and trying to keep up with the rhythm.
                <br /><br />
                We had fun, and I'm grateful to my friend for roping me into the class. I'd join again (probably still in the beginner class)!
            </div>
    },
    {
        title: "Happy New Year!",
        date: "01/15/2023",
        tags: ["New Year"],
        content:
            <div>
                It's 2023! You know what that means. Because some random person thought the Gregorian Calendar was a good idea, we all collectively come together and think self-improvement.
                <br /><br />
                What do I want for the new year? I'm not sure. It'd be nice to be home more (I loved winter break, a whole damn month). And I'd like to DO more things around LA. Friends are graduating this year and I'm starting to realize I have to take more initiative to get out there and get to know the city I go to college in.
                <br /><br />
                I want to meet more people. The juggling club meets in the area outside of my last class on Fridays, so I'll probably head out and join them for a bit. One of my floormates who I play tennis with is active there, so it'll be a nice environment to try something new with new people.
                <br /><br />
                And I want to appreciate things more. I feel like half the time I'm just floating through life not really realizing how great I have things. So, mindfulness! "Mindfulness is the basic human ability to be fully present, aware of where we are and what we're doing, and not overly reactive or overwhelmed by what's going on around us." - mindful.org
            </div>
    },
    {
        title: "Happy Lunar New Year!",
        date: "01/25/2023",
        tags: ["New Year"],
        content:
            <div>
                Another excuse to go home! In the few days before Lunar New Year weekend, I realized the new year was coming on Sunday. I asked a friend if they were going home, and we spontaneously decided to make the long drive back to see our family.
                <br /><br />
                It was nice having good food for the weekend. Not just good, but AUTHENTIC. I get sick of the dining hall food sometimes. Somehow I was never bored of eating the same thing every day when I made it this past summer, but I get bored of the lack of variety around here.
                <br /><br />
                Nothing better than hanging out with family and enjoying some good food with them a couple weeks into the quarter (it feels like eons have passed since winter break geez). Challenge stay at school for an entire quarter failed once again.
            </div>
    },
    {
        title: "I Touched a Piano?",
        date: "02/11/2023",
        tags: ["Music"],
        content:
            <div>
                There's only a few things these days that take my mind off school. Fun activities that I wish I could do more - tennis, going to the gym, and walking around with friends.
                <br /><br />
                Last week, Jeff and I went to UCLA's Con Brio String Orchestra's Winter Concert. He has a friend that plays, and we both know the conductor. It's always nice to listen to a live orchestra. They're also pretty good, especially considering they'r a student-run, non-music major group!
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/SightReading.png")} alt="SightReading" />
                    <figcaption>Maestro</figcaption>
                </figure>
                Going that night gave Jeff and I some inspiration to get back into our classically trained backgrounds. Jeff booked a music room on Wednesday and brought his viola along when the day came.
                <br /><br />
                The piano seemed like it hasn't been maintained in years unfortunately - strings broken, keys stuck, and some suspicious pedals. But that didn't stop us! Jeff wanted to find a piece to duet, so he suggested Love Theme, a piece from a movie called Cinema Paradiso CBSO played.
                <br /><br />
                Here's a little snippet of our <a href="https://www.youtube.com/watch?v=CKBK-9H-wC4">sight-read performance!</a> Had a lot of fun being an accompanist for the first time! We'll be back! Maybe we'll practice Love Theme, maybe we'll mess around with other pieces.
            </div>
    },
    {
        title: "Winter Update",
        date: "02/21/2023",
        tags: ["Classes", "Gym"],
        content:
            <div>
                And that's it - the end of winter 2023 midterms season comes as Computer Networking wraps up our midterm (yes, our first and only midterm is in Week SEVEN!!!).
                <br /><br />
                This quarter has been one of my busiest. The homework, projects, and essays just don't stop. But through all the stress, I feel like I've thankfully found time to have fun and grow as a person.
                <br /><br />
                Juggling club and tennis have become a weekly part of my schedule. It's nice to have time specifically blocked off to have fun. In juggling club, I've been working on a few ball tricks, juggling rings, and learning very slowly how to juggle pins. The most wholesome part, besides meeting and talking to other jugglers, is the many onlookers we always have. Many of them come up to us to share really sweet comments, and many ask with admiration, "what's the hardest part of juggling?" Like most anything else, the answer is literally just practice and so much bending down to pick up balls you drop that your hip is sore the next day.
                <br /><br />
                And perhaps my biggest accomplishment is finally discovering how fun it is going to the gym. A couple of my closest I met on our floor last year have been teaching me the ways of getting ripped. It's hard to believe I used to dread going into gyms. It always felt too intimidating - it's scary going places you feel like you have no idea what you're doing. I'm eternally grateful to have people to show me around and wait patiently as I take off the massive plates they use to put on my little kid weights.
                <br /><br />
                We've been going around 3 times a week lately! It's hard to describe how good it feels breaking physical barriers and seeing the progress. Let's hope this motivation doesn't die down after this period of what my friends call "exponential growth at the beginning".
                <br /><br />
                Anyways, maybe I'll update this with a picture or two sometime soon. I went to UCSB the other day! Gonna go to bed now. Just wanted to get this little stream of conscienceness out before the day ended. It's almost 12:43 AM and I have an 8 AM that I'm definitely not making it on time to tomorrow.
            </div>
    },
    {
        title: "3rd Year",
        date: "02/27/2023",
        tags: ["Adventure", "Summer"],
        content:
            <div>
                I get to go to school in LA, and I don't think I took advantage of that last year.
                <br /><br />
                This past summer, I met some of the smartest people I've met and saw how they lived pretty fun lives outside of school. Living that life with them gave me a sense of how to balance life and school, and for that I'm very grateful. The next few blog posts will be about some of the adventures I've had this quarter.
                <br /><br />
                Note: I'm writing all these blog posts today, but I'm gonna date them to the days they actually happened on (the oldest couple posts appearing below "Winter Update").
            </div>
    },
    {
        title: "UCSB",
        date: "02/19/2023",
        tags: ["Santa Barbara", "Adventure"],
        content:
            <div>
                One of my friends was planning on seeing a friend at UCSB the Sunday before Week 7. She wanted to drag her roommates and me out there (and I wanted to go since I've always wanted to see UCSB), but I had a midterm the coming Tuesday. Her roommates and I agreed to go and just spend the day studying in the library.
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/SB_Beach.jpeg")} alt="SB_Beach" />
                    <figcaption>Sunset at the Beach</figcaption>
                </figure>
                By the time we got there, I thought it'd be kind of lame to just go all the way there and not see anything but books and people studying. So, I texted a picture of the library to a high school friend and we ended up meeting up to get smoothies in Isla Vista (their college town) and see the beach.
                <br /><br />
                It's so unfair how UCSB just has this in their backyard. Every day a lot of their students and community members head out to watch the sunset on the beach. It's stunningly beautiful. Sure am glad I went on the little trip up to SB.
                <figure className="right">
                    <img src={require("./BImages/SB_Pink.jpeg")} alt="SB_Pink" />
                    <figcaption>Unedited Pic of Sunset</figcaption>
                </figure>
            </div>
    },
    {
        title: "Queen of NY Visits",
        date: "02/20/2023",
        tags: ["Adventure"],
        content:
            <div>
                Just a day after our UCSB trip, my favorite New Yorkers came to visit LA. Their identities will be hidden, but if you're reading this, you probably know who I'm talking about! Kids are starting to think about college, and I got the honor of showing them around our beautiful UCLA. Seeing them and hearing about their first ever CA tour put me in a good mood for the rest of the day.
            </div>
    },
    {
        title: "Asian Haircuts",
        date: "02/22/2023",
        tags: ["KTown", "Adventure"],
        content:
            <div>
                My best friend and roommate has been wanting to get a haircut for some time, and I asked him to hold off until after midterms so I could come along without feeling stressed.
                <br /><br />
                Wednesday afternoon finally rolled around. With a morning of classes behind me, we were ready to hop on a bus over to KTown.
                <br /><br />
                We took a different walk to the bus that goes directly to KTown. A walk through a neighborhood which looks so upscale compared to the rest of the Westwood, and an area I'm surprised I never noticed before.
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/KTownHaircut.jpeg")} alt="KTownHaircut" />
                    <figcaption>Moments Before Disaster</figcaption>
                </figure>
                Winter quarter is hell as usual, so I was still studying on the bus. No time wasted! We got to our haircut place an hour early so I took a nap on their waiting room table until it was our turn.
                <br /><br />
                I never want to bother with styling my hair because I don't want to put things in it, and I just can't be bothered to add something else to my morning routine. So, as usual, I asked to keep things simple. Fade the sides and cut off a bit from the top, not too much, not too little. I came out of it looking pretty dumb, and now I just wished my hair grew faster.
                <br /><br />
                My roommate was upset I said no when my barber asked if I wanted him to style my hair, since he wanted to just see what my hair COULD look like. Maybe I'll get a perm one day. But for now, $60 without tip for a haircut is wayyyy too much. I won't be heading back anytime soon.
                <br /><br />
                The trip itself was pretty nice. I like traveling on the bus during the daytime, and I didn't feel like I lost much time since I studied most of the time besides the haircut itself. Our little excursion was so fast that we made it back in time for Italian Night at Epic. Bone marrow, gelato, charcuterie boards, oh lord. Wednesday was a good day.
            </div>
    },
    {
        title: "Universal Studios (Stormy ed.)",
        date: "02/24/2023",
        tags: ["Adventure", "Universal Studios"],
        content:
            <div>
                Super Nintendo World opened up last week! My roommate and I talked about going since last quarter, so we booked tickets several weeks back. Little did we know that LA County would issue blizzard warnings for the first time since the late 1980s.
                <br /><br />
                We went with a friend and floormate we're rooming with next year. With ponchos we bought the day before, we were ready to take on Universal.
                <br /><br />
                We arrived around 8:30, took a few pictures, then headed in. They place a Mario store near the entrance, so we talked to staff there about how to get a spot in Nintendo Land (online they say entrance isn't guaranteed). The staff member told us to wait till 9 AM when reservations opened, but nothing opened at 9 AM! Little did we know that while we were waiting in that store, everyone was getting ahead of us walking towards Nintendo Land!
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/Mario.jpeg")} alt="Mario" />
                    <figcaption>Moments Before Disaster</figcaption>
                </figure>
                By the time we finally got on our way to Nintendo World, a ton of people were already there. But it didn't matter. Nintendo World was damn beautiful. I thought the advertisement video online was faking stuff because it looked too much like the real games. But the world really looks like that. It looks like you're actually in a Mario world.
                <br /><br />
                They make you pay for a $40 "power-up band" that lets you play the mini games which give you entrance to Bowser Junior's castle for the ultimate challenge, so we bought those scams. THey also let you hit the question mark blocks around the land which was probably the best part about it haha.
                <br /><br />
                The Mario Kart ride itself and the minigames weren't all that exciting. They're clearly aimed for a very young audience ahaha. But waiting in line for Mario Kart was pretty cool. They have Bowser's castle, complete with a huge Bowser statue made out of what looks like stone, Mario music, and all decorations of all things Mario world.
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/Bowser.jpeg")} alt="Bowser" />
                    <figcaption>Bowser the Bad Boy</figcaption>
                </figure>
                We spent the rest of the day dying in the rain, hands wet and freezing from the wind chill, and feet sloshing around in water. But it was a blast. There were no lines anywhere else, so we rode all the rides we wanted to ride on multiple times (and got stuck on the Harry Potter Hogwarts ride both times we went on).
                <br /><br />
                Something that surprised me was the locker system at Universal. Rides like Mummy and the Harry Potter Hogwarts ride don't let you take items on board, so you get a free temporary locker. I felt like society really was advancing because users reserve and unlock lockers using their fingerprint!
                <br /><br />
                My favorite rides were easily the Jurassic World and Mummy rides because they were the only actually thrilling ones.
                <br /><br />
                <figure className="left">
                    <img src={require("./BImages/Citywalk.jpeg")} alt="CityWalk" />
                    <figcaption>The End of Our Rainy Night</figcaption>
                </figure>
                To catch our Uber back to the dorms, we had to run through Citywalk through the hardest rain and wind I've ever felt. We even had to hop over the little gate bordering the entrance to the security gates in front of Universal since the area was flooded to the point that my ankles got wet.
                <br /><br />
                Even so, a very fun and very worth it day! I 1 million percent recommend going to Universal in the rain (in the rare chance it rains in LA geez) as long as you wear boots and pants that don't soak up all the water.
            </div>
    },
    {
        title: "The Case of the Chickpea Panisse",
        date: "03/02/2023",
        tags: ["Academic"],
        content:
            <div>
                "Can I please get 3 pieces?" It was an otherwise normal night, but BPlate was serving <b>chickpea panisse</b> at one of their less popular vegetarian stations. I don't know much more about chickpea panisse than that it's absolutely bussin, so I wanted more than what they normally serve.
                <br /><br />
                The staff member looks at her supervisor, who motions to give only the standard 2. Alright, that's fine. I'll just come again and ask for more later. There's no line anyways.
                <br /><br />
                I come back, ask for more, and sure enough get what I want. I ate 6 pieces of chickpea panisse that night. So, if I managed getting 6, why was I barred from getting 3? I got what I wanted, but at what cost? Efficiency of everyone took a hit that night. I had to come back to the station 3 times, and the staff had to come 3 separate times to serve me (I have a "special order" since I ask for nothing but the chickpea panisse itself, which is usually served on a bed of healthy foods crops I never knew existed until BPlate). It was a lose-lose for everyone.
                <br /><br />
                Virtue ethics dictate that the staff acted morally - equality is generally good and one should abide by the established rules. But pragmatism and utilitarianism both suggest that providing the requested serving size brings about the desired outcome in less time while also maximizing happiness for everyone involved.
                <br /><br />
                As you've probably guessed, this isn't really about chickpea panisse. <b>How much efficiency is wasted in situations where the same action can be achieved if people are willing to budge a bit on protocol?</b> I have no clue - it's impossible to quantify but fun to think about. A more tractable question might be why some people are such sticklers for protocol.
                <br /><br />
                For one, protocols bring stability and predictability to situations where inputs are dynamic. The Internet just wouldn't work if it weren't for the massive number of standardized protocols that all hosts have agreed to follow as a participating entity. <b>But in low stakes environments like the land of chickpea panisse, it might be better to act a bit looser</b>. At worst, it averts nasty interactions with those who like to have their way, and at best it makes everyone smile just a bit more.
                <br /><br />
                P.S. Most of my blogs are just light-hearted life updates and diary moments for my family to read. I think it'd be nice to start a thread of #Academic posts where I talk about random thoughts and anything interesting.
            </div>
    },
    {
        title: "Apple's Atrocious Assistance: iPhones, Kernel Panics, and Random Restarts",
        date: "03/27/2023",
        tags: ["Opinion"],
        content:
            <div>
                Disclaimer: my experience may not be representative of others'. No competing financial interests.
                <br /><br />
                $500. The fee that Apple has decided a "complete" repair is fair for phones out of warranty.
                <br /><br />
                My phone has been randomly freezing and restarting 8 to 15 times per day. A few Google searches lead me to some logs hidden in Privacy and Security detailing what Apple calls kernel panics - any error the kernel can't fix. As with all things Apple, it's almost impossible to get further details online. Everything's proprietary.
                <br /><br />
                After 2 months, I finally brought my phone in to the Genius Bar where the sales rep runs their diagnostics app and shows me some panels that simply confirm there's a "System Error." The rep explains how, with that many crashes occurring every day, they recommend a complete repair - replace everything inside the phone.
                <br /><br />
                Suspicious! The phone operates perfectly fine when it's not crashing. It can be reliably rebooted. Yet, the option they recommend is completely nuclear - replace all internals, but not the screen and camera which are conveniently the only parts users can actually see. No module testing? No narrowing down the issue?
                <br /><br />
                Almost all of the kernel crashes were a result of things going wrong with "wifid" or "logd." Daemon processes! I was pretty convinced the issue was software, so I was disappointed the rep recommended a $500 hardware replacement. I brought my phone back, transferred all my photos to an external drive, and reset the firmware. It's been half a day now, and no problems at all. It's unfortunate a company as popular as Apple takes advantage of users when things go wrong outside of warranty.
                <br /><br />
                I'm still a big Apple fan. I respect the technology, design, and services. But their support process needs some reevaluation. <b>Their servicing pipeline for this kind of an issue is far too shallow of a decision tree.</b> Run diagnostics &#x2192; system error &#x2192; out of warranty &#x2192; $500 complete replacement. It wouldn't be difficult to implement more rigorous diagnostics to find the root of the issue and replace only the software or hardware components that are necessary. This would not only leave customers happier, but is more sustainable for Apple and the environment.
            </div>
    },
    {
        title: "AI",
        date: "04/07/2023",
        tags: ["Opinion", "Academic", "ChatGPT", "LLMs"],
        content:
            <div>
                LLMs are insane. ChatGPT is scarily impressive.
                <br /><br />
                It's a natural response to what feels like the first computational entity that possesses some sort of general understanding. But it's an illusion, albeit an extremely powerful one.
                <br /><br />
                I keep up with debate about AI, its applications, and its proposed policies via Twitter. I'm there pretty every day, reading unfiltered opinions from tech leaders, AI researchers, and  random people like myself. I don't think anyone can fully understand how generative AI will shape our future just yet.
                <br /><br />
                A lot of concern right now, though, surrounds the impact of generative AI - specifically Large Language Models like ChatGPT - on jobs like software engineering. It's relatively clear that ChatGPT can't replace humans as of now. We have greater world understanding, context comprehension, problem solving, and reasoning abilities, all of which are vital in designing and engineering good systems.
                <br /><br />
                But that's not to say LLMs aren't extremely useful. This very website has benefited tremendously from LLM-aided-engineering. Finding an appealing color scheme (for both light and dark mode), making the navigation bar's border well-behaved when the page is zoomed, styling comment boxes, setting up authentication for commenters, and my favorite - pop up warnings for errors when signing up or logging in - all were greatly accelerated by ChatGPT. I can confidently say ChatGPT shaved at least a couple hours off my recent site upgrade.
                <br /><br />
                All this to say I'm both excited and nervous to see how the engineering fields will react. As we're deluged with AI products that bring CS research from labs to general people, I'm excited to see what more there is in the product space, and even more so to see how people will develop and evolve new, more powerful architectures.
            </div>
    },
    {
        title: "I Benched a Plate",
        date: "04/20/2023",
        tags: ["Gym"],
        content:
            <div>
                I benched a plate today. That is all. Thank you.
            </div>
    },
    {
        title: "LA Hacks, Victory and Defeat",
        date: "04/26/2023",
        tags: ["CS", "Hackathons"],
        content:
            <div>
                Leo, his high school friend, and I did LA Hacks together this year. We didn't go in with much of an idea of what to do, so we spent much of the first few hours biking and walking around outside pitching and telling each other how stupid our ideas were.
                <br /><br />
                We all know the world has a long way to go in terms of improvement - sustainability, safety, you name it. But somehow, when we're trying to come up with fresh ideas, it feels like everything there is to be done has already been done.
                <br /><br />
                Thinking along sustainability and apps in the past that have connected consumers to discounted items at restaurants that would've otherwise thrown out the food, we started thinking about food waste. There are many metrics for environmental impact, but did you know food waste accounts for almost 10% of carbon emissions alone? Not previously considering how big an impact thrown out food has on our world, we developed an app that allows stores to list items that are about to expire at discounted rates for our users. To make this idea come to life, we collectively got maybe 8 hours of sleep among the 3 of us from Friday night to Sunday night.
                <br /><br />
                <figure className="right">
                    <img src={require("./BImages/LA_Hacks_Pauley.jpeg")} alt="LA_Hacks_Pauley.jpeg" />
                    <figcaption>LA Hacks 2023 at About 2 AM</figcaption>
                </figure>

                We didn't win anything, but we were proud of achieving our goal of coming out of the weekend with a solid product. But there was still a victory - I defended my title in the LA Hacks typing tournament! That makes two years in a row, although I'd say last year's competition was somehow more competitive. This year we were in Pauley and the competition was in one of the clubhouse room's, so I expected more competition. But maybe only half the people as last year showed up.
                <br /><br />
                Maybe that's because they had inside information on the prize, which is decidedly 10x lamer than last year's - a Blender bottle. Not only do I already have a Blender bottle, but that prize just doesn't measure up with a mechanical keyboard ahaha. Nevertheless, I'm grateful and it looks like I'll have to continue the tradition of giving my brother my LA Hacks typing tournament prize.
            </div>
    },
    {
        title: "3rd Year",
        date: "06/07/2023",
        tags: ["Classes", "Milestone", "Life"],
        content:
            <div>
                I just finished the last homework assignment of my third year.
                <br /><br />
                Relief, happiness, sadness. I can't tell what I feel, but above all else I'm grateful for what a good year we've had. It's been quite a while since I've been here, and I'm looking forward to reflecting on how life has been like.
                <br /><br />
                I have conflicting opinions on this quarter's classes. An anthropology GE that was so well-organized, well-taught, and well-supported by a wonderful teaching staff didn't peak my interests much. A bioinformatics class exploring data structures and algorithms used throughout modern comp bio research gave me insight into new & interesting techniques, but wasn't particularly well taught. And a programming languages theory class that made me realize all programming languages can be framed around the same general concepts (and so can be picked up relatively easily once the theory knowledge is there) covered both intensely interesting and mildly mundane topics.
                <br /><br />
                My thoughts are jumbled as I study for finals, but below is a list of some of my favorite work from the quarter
                <ul>
                    <li>Writing an interpreter for a new, statically-typed language that supports object-oriented programming, local variables, templating, and exception handling</li>
                    <li>Writing a read mapping tool (to align sequencer reads to a reference genome) that handles mutations, insertions, and deletions, from scratch, using only Python's standard libraries</li>
                    <li>Writing a genome assembler (to assemble a reference genome from sequencer reads) from scratch (using only Python's standard libraries)</li>
                </ul>
                Life this quarter has been much more than about academics. The people in my life have given me ways to embrace a more spontaneous attitude. To go shopping for things we don't need (Taylor Swift vinyls are just too tempting), to bike around campus for no reason other to talk and see the beautiful nights, to work out even though I should be taking a break from weight lifting, to go to more gatherings, to pester people walking to class to vote for the transit referendum, to go to Santa Monica instead of finishing a project, to meet new people, and to spend time talking about bullsh*t and playing dumb games way too late into the night.
                <br /><br />
                Among all things, a one-year unlimited Metro Bike pass had a significant impact in my life. A good friend of mine - an environmental science major - gave me the pass so we could more easily get around the area. The bike pass ended up enabling all sorts of activities - from delivering for Uber Eats, to getting locked into a stairwell at an apartment we delivered to, to exploring Westwood, Santa Monica, Sawtelle, and campus. I would've never gotten to know the streets around where we live if it weren't for this pass. Seeing how centrally located UCLA is, how easy it is to bike to diverse areas for food and relaxation, makes me even more grateful to be here in LA. The current profile picture was taken at a bike path along the way from UCLA to the Santa Monica Pier!
                <br /><br />
                With undergrad a year out from ending, I'm starting to realize there's still so much I want to do. I've yet to join a club with a bigger group/community where I could meet and hang out with people while also doing something productive and fun. A friend really enjoyed a beginner dance club, but the time commitment is scary (more than a part time job). Seeing him light up along with his new friends at the performance showcase made it all seem so worth it, though. I'd regret, later in life, not joining. So I'll make my best effort to make time for it next year.
                <br /><br />
                An entire summer lies ahead now! Life has given me many unexpected surprises this year. I thought I'd be traveling to Salt Lake to meet and work with people in biotech that I think are approaching drug discovery in fruitful ways, but things happened and I need to take care of some loose ends. I'm hopeful and excited that things will turn out okay. Sure I won't get to meet all the people I thought I could, and I won't have those serendipitous encounters while walking around the office and the city (some of my most treasured moments from last summer). But I'll be surrounded by friends and family, and I couldn't ask for a better support group. These loose ends have mostly been the reason why I haven't blogged, but I'll be writing here more throughout the summer as things get cleaned up. Cheers to the close of a wonderful chapter of happiness, new friends, and - for the first time in my life - a sense of balance between celebrating life and managing school/work on the side.
            </div>
    },
    {
        title: "Jucipardi",
        date: "08/05/2023",
        tags: ["LA"],
        content:
            <div>
                Jucipardi, pronounced 'ju-sih-per-dee'. A word that rolls off the tongue when said swiftly, and a word that is fake but represents a very real place.
                <br /><br />
                The city I stayed in during the first half of summer gets a fun alias. Jucipardi is nice. Jucipardi has vibrant communities, busy malls, and wide, shared car/cyclist roads where drivers nevertheless honk bikers as they coast by.
                <br /><br />
                Being in a nice city means lots of exploring, eating, and shopping. The more places I visit and repeat this pattern in, the more I feel like I don't even know my own hometown. Jucipardi is in San Gabriel Valley, better known as the 626. It's a bit of a culture shock to see how homogeneous the population around is, so much so that even the most common US banks have signs in Asian languages.
                <br /><br />
                It was my first time living with a dog! It's crazy how quickly you grow to love dogs. Just a smiling, playful, energetic friend who's always there as long as you pamper them every day.
                <br /><br />
                Jucipardi also marked the start of my work from home era. I'm grateful for the flexibility of WFH, but it really can be draining sometimes to work and not see coworkers around, have no way of casually making friends, and no physical separation between work and personal life.
                <br /><br />
                I can't remember a time I felt so much writer's block on a little blog post. I guess I won't say much more since these past several weeks have been a very personal journey. Just wanted to record a little something here before I forget everything that happened.
            </div>
    },
    {
        title: "Rolled Ice Cream",
        date: "08/21/2023",
        tags: ["Food"],
        content:
            <div>
                I had rolled ice cream for the first time last night, and why does it literally just feel like normal ice cream. Disappointed! I guess I paid the price of a lunch for the pics and vibes.
            </div>
    },
    {
        title: "New Beginnings: The Last Year",
        date: "09/22/2023",
        tags: ["4th Year"],
        content:
            <div>
                One week done, 12 more to go. Today marks my first week living in an apartment in LA, the last day of my internship at Recursion, and the end of our free week before Week 0. Fall quarter officially starts next Thursday!
                <br /><br />
                Moving out of the dorms and into the apartments has been easier than expected. More spacious rooms, a nicely designed closet, and a big enough kitchen where I don't feel cramped (but, only half of our apartment is here right now lol). A couple of my apartments don't cook, which is great for me! I've been cooking every meal fresh, which I find fun, but I'll definitely have to start meal prepping once school starts up. The time spent cooking and cleaning stacks up fast.
                <br /><br />
                Well it's Oct 13 now and I never finished this, so just gonna move on lol.
            </div>
    },
    {
        title: "Fall",
        date: "10/13/2023",
        tags: ["Fall", "4th Year"],
        content:
            <div>
                The season of new beginnings. Fall is officially underway, and though not actually considered the season of new beginnings, Fall has always felt fresh, alive, and exciting. Many other students just getting back into school probably relate.
                <br /><br />
                Just a month has passed since moving back to LA, but life already feels fuller in many ways - meeting new friends, becoming closer with old ones, and living life through college, not college through life.
                <br /><br />
                I've never felt this content in college. Cooking, taking over the apartment building's front desk, going to KTown, dancing, biking, and escaping with friends. Things feel as meant to be as they ever have. A lighter courseload, clarity about life after college, and a community of people I feel safe to be myself around have given me the space to lead a fulfilling last year.
                <br /><br />
                There's time to read! I'm currently on Billie Jean King's autobiography, a piece that demonstrates superhuman levels of self-awareness and maturity. Though I bought her book because I'm a fan of her sport, it's easy to see how her way of life can be inspiring to anyone looking to fight for a more equitable future. Her ideas and philosophies feel especially relevant nowadays.
                <br /><br />
                Though I enjoy getting lost in BJK's thoughts during my spare time, I've also had much time to think about my own. This summer has fundamentally changed my perspective on life. I'm happy to be here, and I want to experience everything there is to see. Being spontaneous, saying yes more to friends, and valuing the people that share a positive outlook on life. My friend recently described me as too jolly, and that comment hit me harder than he'll ever know.
                <br /><br />
                I feel like I always get overly sappy in these little reflections, yet I don't think anything is dramatized. It's difficult to strike a balance between keeping my private life to myself and leaving something meaningful for the special people that follow my shitty blog. Maybe I  should really put these types of things in a diary, not on the Internet. But I also recognize this little post will have little significance in the grand scheme of things. I've always found an odd comforting feeling in that anonymity you get when you realize you're just one among a crowd of so many others.
                <br /><br />
                So, while I do care who sees this, I also don't. I'm excited to see how Fall goes, and I'm happy you're here along with me to see where things end up.
            </div>
    },
    {
        title: "Open Letter to Fall",
        date: "12/17/2023",
        tags: ["Fall", "4th Year"],
        content:
            <div>
                I'm on the plane home from fall quarter. I was careless about checking in, so my boarding position was towards the end of the second group, yet I'm sitting in the front row. Asking sometimes really does get you what you want :D. A couple familiar faces in the plane - people I've never talked to but recognize from school. I wish I said hi while we were in the TSA line.
                <br /><br />
                This quarter was insane. Making trips around SoCal, getting horrified at Universal, lighting up Christmas at Disney, driving hours just to shop at a Japanese market and see some Christmas lights in a ridiculously boujee neighborhood - we've lived so much more this quarter than my sophomore self could've ever imagined.
                <br /><br />
                Part of it was a light class schedule. Part of it was the new attitude living in NYC gave me. Most of it was the people. Thinking back through the weeks, I realize I'm spontaneous and excited to get out, but I don't take as much initiative as I could.
                <br /><br />
                If it hadn't been for my friend saying we HAVE TO go on a little trip that one 3 day weekend we had (we ended up at the Channel Islands), for another who brings up all the best places to see lights and eat around OC (Pho 79, Da Vien, oh my), for another who's always down to come (just tell me how much to Venmo!), and for all the others who I get to spend time with, college wouldn't be the unforgettable time it's shaping up to be.
                <br /><br />
                I haven't talked about classes yet, and that's intentional. Class has felt somewhat secondary this quarter. The intro psych class felt as freshman oriented as it was meant to be, the Korean class was as fun as I hoped it'd be (and I loved how our class was a little community), the Machine Learning in Genomics class was largely review, and the computer architecture class was a fair amount of studying and work, but nowhere as stressful as past CS classes.
                <br /><br />
                As my closest friends prepare to graduate in winter, I can only hope next quarter is as light as this one. Because let's face it, am I gonna remember getting an A in a class, or freezing and laughing late at night in a giant line for some donuts that weren't worth the wait, 50 miles away from our apartments; accidentally taking the express lane at midnight when the highway was empty; waking up at 5 to go to a land for children thinking we'd be the first ones in and last ones out (we left early); blasting songs in our Zipcar; shopping for hours at a single Japanese mart; getting lost in the mart and shouting ridiculous names to find each other; cooking together in our apartments; eating out; yelling stupid Japanese and Korean phrases in our room; baking a blackened egg tart pie; and distracting roommates while they're studying.
                <br /><br />
                This quarter wasn't all fun and games, but that stuff isn't for posting to the public blog :D I'm fortunate to constantly be around people that make it all worth it though.
                <br /><br />
                As I look to my final couple quarters here, I realize I want to find spaces to connect with more people. Both the superficial "know their name and say hi" type of connection, and the hanging out outside of class type of way. The balance between meeting new people and spending time with the ones you cherish is something I have difficulty with. But I'm grateful to be where I am. College has shown me so many people's perspective and after living these past weeks with 3 transfers in the other room, I'm just beginning to realize how difficult college life can be for transfers. Classes are filled with people that've already found their people. Your first classes at the school are upper divs. Expectations are high. And in many cases, there's no dorm environment to facilitate all the serendipitous little conversations that lead to friendships. For some it's easier than others. But for those others, I can see how college can feel so isolating. I'll try to be more intentional with including my transfer apartmentmates in our lives next quarter. It took until finals week for us to finally sit down and hang out with one of our apartmentmates, and  we had a wholesome time. It just takes a bit for some to open up.
                <br /><br />
                As I look out the window past this guy sitting in the window seat, I'm seeing the edges of home. Landing time coincides with what feels like a good place to wrap this up. Family is close again. And we have a lot of time to spend together this break, something we haven't had for years. I'm looking forward to it.
                <br /><br />
                I likely won't be here again till 2024. So to all my passionate fans, thank you. Glad to have you here. If you're reading this, you're either a recruiter, family, friend, or a snooper. Regardless, I love you and hope you have a good rest of your day. Happy holidays and have a great new year.
            </div>
    },
    {
        title: "Checking In: Winter 24",
        date: "02/21/2024",
        tags: ["Winter", "4th Year"],
        content:
            <div>
                The past 2 months will be 2 of the most memorable months of my life. It feels weird to say that considering I haven't gone on as many adventures as last quarter. But there's been a change in the interpretive lens I use to view college life. Every minute feels more sentimental knowing it's the last couple months I'll spend living with the first people outside my family who've helped define what life means to me.
                <br /><br />
                But enough with the sad stuff. As I prepare to take on the last month before a couple of my closest friends graduate, I thought it'd be nice to look back on how things have changed since the beginning of this year.
                <br /><br />
                I joined club tennis (Bruin Club Tennis, or BCT)! It only took until the second quarter of senior year to find a social club that I really loved. We realistically don't hit that much in BCT, unless we're winning a game, but I've grown to love BCT because of the people. Spending a couple hours two nights a week in LATC, chatting with friends, and playing some tennis makes me feel so connected. So surprised when I find out how many mutual friends we all share - I don't think I've ever commented about how small the world is this many times before. So energized when I play doubles with a new friend who was, just a few weeks ago, a complete stranger. And so happy that we all came together over a shared love of tennis.
                <br /><br />
                You might think most of the friendships born out of BCT aren't long-lasting. Not in the sense that we don't like each other, but in the sense that we're friends on the court and don't really feel a natural connection otherwise. And that's true, we have a blast together every practice, but most of us don't hang out outside. I was lucky to vibe with someone quite a bit since the early weeks of BCT, so I introduced them to my best friend after a couple weeks. They actually go to a university he studied abroad at, so the 3 of us have become a trio, hanging out quite a bit, making dumplings, and sitting on the crappy busses to get to KTown where we enjoy good food, long walks, familiar foods at new stores, bad WiFi at fancy cafes, and long waits at creepy bus stations.
                <br /><br />
                All this activity has shown me I need to live a more sustainable life. I love going out, eating, cooking, and visiting new places. But it sure does eat up my savings. How can I keep up with my college lifestyle? Time to find a part time job!
                <br /><br />
                I'm very privileged in the sense that I'm at a place in life where I can work jobs I love. As I looked around for remote ways to earn some cash, I thought about what it is I like to do. CS, of course. But why would I want to do more CS outside of class and lab? Tennis? No, I'm not good enough to teach. Languages! Yes! I've always enjoyed learning languages - Chinese (a language I was illiterate in a few years ago), Korean (I have international friends and enjoy Korean ballads), and English (the more I read, the more I realize how well other people can write). And so I picked up a job teaching Koreans English! It's been a fun way to think more about language learning, and my favorite part is meeting all kinds of people. Those trying to get better so they can use English professionally, and younger students on their way to sounding like native speakers.
                <br /><br />
                Besides that, this quarter has been pretty much all fun and games. A couple weeks ago, my friends and I headed out to Knott's Berry Farm. Knott's is a very wholesome place. It's clearly built for kids, but it's not so childish to the point that adults feel out of place (ummm Disneyland?). Knott's is a smaller place with all sorts of crazy rides. Rides where your feet hang in the air, where you go upside down, and where you're literally climbing a straight vertical into the sky. It's insane, but my amusement-park-loving-friend made sure we knew Knott's was nothing compared to Six Flags. I'll have to work up some real courage before ever going to a Six Flags. I thought Knott's was insane enough.
                <br /><br />
                The craziest, in the sense of being out of character, thing I've done this quarter is probably going to a rave. A friend was asking me for about a week to see William Black, an artist I only knew a couple songs from. I said I'd think about it, since I've always believed a healthy attitude to life requires a willingness to try everything at least once. But the concert was on the night of Lunar New Year, and to make it worse, it was just a couple days before 2 midterms. The week went on and I committed to letting my friend down - I didn't want to rave.
                <br /><br />
                But Saturday came along, I had a happy little celebration in my apartment with some food, and I realized I felt ready for midterms. Why not take a break? So, a few hours before the rave, I convinced another friend to come, bought tickets, and got on our way to the concert. Easily one of the most fun nights of my college life.
                <br /><br />
                In more everyday terms, I've finally gotten back into working out. My building's gym is notorious for being bad, and I can see why. No free weights, no bench press, no EZ bar. What's the point? But hey, I like to see the good in things, and if I can hit shoulders, chest, triceps, and mayyyybe biceps if you even count the FTS Glide as being able to let you hit biceps, then whatever. I can avoid the long walk to Wooden and all the lines waiting there. Most times, I can't even get the free weights in Wooden. I think about these apartment gym sessions as training sessions to improve my actual gym sessions at Wooden. But maybe I'm just gaslighting myself.
                <br /><br />
                I've gotten to the point where I feel like I've properly reflected on how this quarter has been going. I've really found the space to appreciate the little time left we have as stupid undergrads. Most of us are ready to take on what lies ahead. But for now, I want to enjoy the last little bit of freedom we have in the bubble that is our current lives.
            </div>
    },
    {
        title: "Happy Happy",
        date: "03/08/2024",
        tags: ["Winter", "4th Year"],
        content:
            <div>
                The past couple of weeks have been some of the happiest of my life. I spend most of my time these days with the people I care most about. Sometimes it feels like I'm barely at my apartment. It's been wild - the other day, I realized I couldn't remember the last time I cooked a proper meal. And I love cooking!
                <br/><br/>
                There comes times when it feels natural to sit down and write one of these posts. This time it feels like so much has changed in my life, and I have a rare evening to myself to actually reflect. The last time I felt this way was a couple nights before going on a trip to San Diego. Winter quarter was taking an unexpected, happy turn at the time. I thought I should capture some of the movement before heading on a bigger trip with friends. Tonight, 2 weeks later, it's once again time to look back on the happy times.
                <br/><br/>
                The last time I was in San Diego was far too long ago to remember anything meaningful. So, this time, I was excited to go see what felt like a new part of the world. And the best part is going with 2 of my closest friends. For the first time, I didn't even have to drive! Being passenger princess was great - I got to look off at the distant, green mountains and the beach bordering the highway on the way south to SD.
                <br/><br/>
                Peaceful cafes, plant shops, neighborhoods, parks, beaches, and restaurants adorn SD. There's such a sense of cleanliness, safety, and peace throughout the area. I can see why people want to retire there. We had a fun day shooting photos, driving down scenic routes, and hanging out. It often surprises me how much you can learn about someone while doing somewhat mindless things like walking around. No matter how much time you spend with people, there's always something more to hear. That's probably my favorite part of these trips - all the time we're just together. I've never been one to care much about what we do or where we go. It's what makes me a terrible decision maker when it comes time to choose a dinner place. But it's also the reason everything feels fun to me when I'm with the right people.
                <br/><br/>
                San Diego wasn't too tiring. The next day we had time to settle down, do some work, and head to the USC rivalry game before going to a fundrager. Watching sports has never really been my thing. Watching anything, really. I'd rather be doing what the people I'm watching are doing. But, I had to go to at least one rivalry game, so no regrets. The fundrager after also made it a night to remember. Met a ton of friends of friends.
                <br/><br/>
                The next week was the start of what feels like a whirlwind couple of weeks. So much happened, and if it weren't for my excessive use of Calendar and Photos, I wouldn't be able to remember it all.
                <br/><br/>
                I visited my roommate's childhood home on a day trip back to his area! There really wasn't much to do, but maybe that's because we didn't plan anything. It's the curse of feeling like there's nothing to do where you actually live. There's just no motivation to actually go out to places that are so close to us. But, we did get to eat some good Asian food and perhaps the most productive part of the day was bargaining at a Jingdezhen Porcelain shop for some bowls my Mom loves.
                <br/><br/>
                I went through a phase in high school and early college of tracing the supply chain of these bowls, since there's only 1 exporter left and they aren't sold at popular stores. My family's recent China trip prepared me well to tell the merchants off when they tried scamming me with their $20/bowl price tag. Sure these bowls are historical, but it's not like you made it with your own hands! Don't pretend like you paid more than a couple dollars to get them! I walked away with a satisfying deal and gave a bowl to my roommate's mom as a thank you for letting us visit. I later found out she was confused why I gave her a bowl, but whatever lol.
                <br/><br/>
                This past Sunday, the same SD trip friends and I headed out to Santa Barbara. The ride there was one of the best story times I can remember. I was driving, so we could all actually talk (LOL). Having people that can chat the whole way there just makes everything 100x better. The actual vibes in SB are similar to SD - clean, peaceful, and plain beautiful.
                <br/><br/>
                I asked a friend that went to UCSB to send some reccs, which landed us at Butterfly Beach and the nearby area. Easily one of the most beautiful places I've seen out here. We also went to a restaurant that was plain ***, so I had to ask my friend if her parents owned the place or something. No way I'd ever send a friend there! But all is okay, because it was funny and gave us more of an excuse to get a good dessert.
                <br/><br/>
                I also bought a couple nice things from SB - a little mug (mug addiction reignited) and a map of the NYC subway systems. I usually think of myself as someone who's not easily affected by those around me. But if you had asked me to buy that map just a few weeks ago, I'm starting to realize I probably would've said no! I literally love this map now haha.
                <br/><br/>
                That's a recap of all the bigger trips that've happened recently. There's just 2 weeks left of school including finals week. I'm sad, but I'm also looking forward to spending more time with the people that have brought so much happiness into my life. I suspect I'll end up staying in LA a bit into spring break to squeeze every second I have left with the people I care most about. But let's not think about that yet. I'll try to enjoy each little moment as much as possible :)
            </div>
    },
    {
        title: "Winter Quarter Recap",
        date: "04/02/2024",
        tags: ["Winter", "4th Year"],
        content:
            <div>
                For the last time, it's once again Week 1. Spring quarter is here and somehow I haven't accepted it's the last quarter of college. It's Tuesday afternoon. 2 days have passed, and I've already attended all the classes I'll be taking this quarter. With only 2 classes and some research left to do, I'm hopeful this quarter won't be too stressful.
                <br/><br/>
                It's time to think about what I want out of this quarter. But before that, I want to allow myself the time to reflect on and think about winter. Last quarter was perhaps the most wholesome 11 weeks of my life. One quick look at my calendar (literally every dumb little thing I do is on there) shows just how much time I spent away from school - not physically, but outside of classwork. Over spring break, I let my brother scroll through my camera roll, and he said something I felt summed it up pretty well. "Holy **** it looks like you were on vacation."
                <br/><br/>
                In many ways, it did feel like vacation. I'm so so fortunate to go to school in LA. But I'm even luckier to keep meeting the right people at the right time. In the early days of college, much like many people, I wondered whether this place was right for me. Now, I can't imagine never having met the people that I now call close friends. I feel like it's pointless to think about how different life would be if things didn't turn out this way. And I know it's a privilege that I wouldn't have my life any other way. I'm so grateful.
                <br/><br/>
                The end of winter quarter wasn't overwhelming by any means. In some ways, finals week was possibly my favorite week. There's no classes! Having finals Wednesday through Friday sucked, though, since spring break really didn't start until the end of the week.
                <br/><br/>
                Spring break was a great time. There's something therapeutic about living alone in a 6 person unit, walking around the empty apartment lobbies, and seeing Westwood at a time almost all  students are gone. There's such a serene atmosphere. Given the emptiness, I was lucky not to be lonely. I spent a few days with close friends, taking a hike (Will Rogers State Park is an easy and very beautiful climb), touring through DTLA, driving through East LA, and hanging out.
                <br/><br/>
                Going back home after those few days was also a great way to reset. Great food, better snacks, my own bed, and good company. There's really not much more I could ask for. Somehow, I'm never prepared for how fast these breaks whiz by.
                <br/><br/>
                As I look to spring, there's many things I want to do. Get a meal with people I haven't before - there are so many familiar faces I say hi to yet have never really sat down and had a proper conversation with. Dance! I might join foundations, more for the social part than the dancing part. Climbing? A few friends are really into climbing, so maybe I'll give it another chance. Working out! Haven't gone to the gym with those climbing friends in a while (we used to go together all the time). And... I feel like there has to be more. But, I can't really think right now. Maybe I'll be back at the end of the week as I keep thinking. For now, let's cheers to a happy spring.
            </div>
    },
    {
        title: "Spring Quarter: Checking In",
        date: "04/25/2024",
        tags: ["Spring", "4th Year"],
        content:
            <div>
                The end of Week 4 is quickly approaching, and I'm just now finally back with an idea of what I want out of the quarter. Took me a lot longer than a week to figure out!
                <br/><br/>
                Probably the hardest question for me was whether I wanted to meet new people or spend more time with the friends I'm lucky to already have. I thought joining a dance group might be fun, so I had an embarrassing audition and was waitlisted, but eventually had the chance to join... which I declined.
                <br/><br/>
                Do I regret declining? Maybe a little. I've always wanted to be backstage, ready to leap out on stage for a showcase at UCLA. It would've been cool to do some sort of performance. But at the same time, I didn't want to be running around all of my last quarter. Tuesday and Thursday nights are for club tennis, so adding dance on Monday and Wednesday nights made me feel a bit restricted. Maybe not spread too thin, but definitely committed to a little too much.
                <br/><br/>
                I like to have open nights. Time I can reach out to friends if I feel like doing something spontaneous - going to the gym, getting dessert, having dinner. I didn't want to have 4 nights a week where I had to rush off to clubs.
                <br/><br/>
                Though, I really do love club tennis. It's always a good time hanging out with tennis friends, hitting, and running around. I used to dislike the 9 PM start times, but the night time walks and cool, peaceful nighttime air have really grown on me. It also helps that I don't have morning classes this quarter.
                <br/><br/>
                No dancing means more time for myself! I'm grateful I can spend time with everyone I want to see. It just means I have to be more on top of planning things and reaching out! I'm really only taking 2 classes this quarter, a CS capstone and a research communications class, so I thankfully do have time to enjoy these last few weeks as part of the UCLA community.
                <br/><br/>
                This past weekend was LA Hacks! Can't believe it's already been a year since the last. I didn't really have any motivation to stay up and build a project, and thankfully, neither did my friends! We decided to hack our homework, which ended up just being a couple days of going out to Santa Monica, KTown, and Culver City to eat food, climb, hang out, and do homework late into the night after the sun set. I didn't even sign up for LA Hacks this year - I checked in under a friend's name who wasn't attending (thanks gyqls)! BUT THERE WAS NO MERCH LEFT BECAUSE I CHECKED IN 6 HOURS LATE. Oh well, at least I scammed my way in.
                <br/><br/>
                There was only really one event I wanted to attend - the annual LA Hacks typing tournament. I've won the past 2 years and gotten a couple really cool prizes! My first mechanical keyboard in 2022, and a shaker bottle (much cheaper than the keyboard lol) in 2023. So, after a trip to Louders in KTown (it's decent, but really heavy food), we rushed back to check in for the tournament. AND GUESS WHAT! When we asked where it was, a staffer told us they cancelled it because they didn't have enough organizers. BOOOO!!! We left and worked on homework the rest of the night.
                <br/><br/>
                Anyways, now that I'm realizing college is really almost over, I'm trying to take every little opportunity I have to enjoy the community here. Going to my favorite gym even though it's a bit farther, taking the longer route back home so I can see campus and bump into more people, and revisiting some of the nice spots to eat on campus. It's only Week 4, so I'll try to live in the moment more before this is all over :D
            </div>
    },
    {
        title: "A Few Weeks Left",
        date: "05/22/2024",
        tags: ["Spring", "4th Year"],
        content:
            <div>
                Last night, it really started hitting me that college is ending. It's sad to realize how fast this quarter has flown by. Luckily, nothing comes to mind when I think of major things I have left to do. I'm happy and beyond grateful for what college, especially this past year, has turned out to be.
                <br/><br/>
                I think I know what I'll miss the most - campus. UCLA's campus is unlike most places in this country. Campus is somewhere I can always go to, however early in the morning or late at night, and feel safe, happy, and peaceful. There's somehow always someone else around. Friends are always close. Places that used to be unknown, but now house some of our most cherished memories. Dorms that we used to live in. Stadiums that we used to play in. Classrooms we used to sleep in. I'll miss the birds, the trees, the grass. I'll miss being able to walk to tennis, the gym, and my favorite restaurants. I'll miss calling my friends to hang out late at night. I'll miss the physical closeness of everything and everyone.
                <br/><br/>
                Classes this quarter have been a bit unexpected. The CS capstone consumes much of my time, but I'm lucky to be going through it with good friends. The workload does stress me out quite a bit, though. I feel like I haven't been so consumed with school work all year long. In many ways, this has been the toughest quarter of the year, at least academically.
                <br/><br/>
                But I've still had time for fun! I've played a lot more tennis and made better friends with the people of BCT's Court 1. Every Tuesday and Thursday night is such a nice little reset. I feel so refreshed after hitting and seeing so many familiar faces, all having so much fun night after night. I'm jealous of all the 1st years who are already such active members within the BCT community.
                <br/><br/>
                Just last night, I was surprised to find out someone was a 1st year. They asked if I had anything to say to a first year, and I said they're already doing things right! It's important to work hard in school, but college is also a time to have fun, and doing more things like being in BCT and making an effort to meet people you want to surround yourself with is the best part of the whole thing.
                <br/><br/>
                Weeks 9, 10, and finals week are all that stand in the way of graduation. It's crazy to think about. I'll take it day by day and try to spend more time with all the people I won't have the privilege of being so close to all the time anymore. I'm excited to make the most of this last little bit of time :D
            </div>
    },
    {
        title: "Graduation",
        date: "06/16/2024",
        tags: ["Graduation"],
        content:
            <div>
                It all happens so fast - our last class, our last presentation, our last final, and then graduation. Before I've even had time to process what's going on, I'm already out of LA. And that's the strangest part. Our lives change so suddenly once we walk off that stage, but life just keeps moving, even though it feels like the whole world should be stopping to take a giant breath.
                <br/><br/>
                I'm sad. Graduating undergrad is the most important milestone of my academic life so far, but the idea of graduation itself was both exciting and depressing. It's finally time to acknowledge that we won't get to live with friends, go out late at night without any care, bump into familiar faces at the most random times in the most random places. But perhaps the most wholesome response to this sadness was something my freshman tennis friend told me - "would you rather be sad that it's all ending, or have none of that happen in the first place?" And he's right. It's such a privilege that I get to be sad about all the happiness that's coming to an end.
                <br/><br/>
                Many people say that college is the best 4 years of our lives. And while I can see where they're coming from, I hope it's not true. The walkability of everything and everyone in college sure does make it a special place. But that doesn't mean we can't put in the effort to build our communities wherever we decide to call home next. We're so geographically fragmented, but isn't it nice that we have a global community of friends now?
                <br/><br/>
                The places I value most from UCLA - LATC (our tennis stadium), Wooden (our gym), Gayley Heights (our apartment), and campus in general - are special because of all the things we've done with people that were, at some point, strangers. Even the places themselves were locations that once had no meaning to me. I'm hopeful that we can all collectively breathe new meaning into the places that surround our new homes, with new people that will also join our circle of those we consider lifelong friends.
                <br/><br/>
                Graduating comes with a lot of reflection. I was at Lazy Daisy eating brunch with a close friend the other week, and he asked if I think I've changed in college. The answer? Clearly yes. My sophomore year, I was so content with staying inside studying all day, working on projects, and going to the library. Not only was I content with that, that was pretty much my entire life.
                <br/><br/>
                Summer after sophomore year came around, and I met some of the coolest, smartest, and most admirable people both inside and outside the classroom I've ever met. I was doing comp bio research in NYC with people that made me realize living a crazy fun life isn't mutually exclusive with doing great things in life. I came back to LA more confident, better dressed, and more excited. Excited to go out, excited to make new friends, excited to play sports, and excited to balance school and life with this new attitude.
                <br/><br/>
                The other turning point in my life was the summer I spent with my friend in LA. That period of time made me realize how lucky we all are to be alive. I thought harder about what it is I want to accomplish, where I want to live, what type of people I wanted to spend more time with, and even what types of people I wanted to distance myself from. I saw how important active reflection on these hard topics was to making the best of my time, and I felt empowered to understand and express my values through writing. This blog helped me a lot with keeping this sense of active reflection, and it doubles as a great historical resource for my life (I've always loved recording the life of me and my friends in every way possible).
                <br/><br/>
                The past 4 (really 3, not counting the 1 year of Zoom) years have been as transformative as I had hoped they would be. It's kind of funny to think back to the nervousness and anxiety over what life would be like in LA. I'm happy to say now, after having lived through it, I wouldn't have it any other way. And I'll treasure every moment for as long as I can. It's crazy to realize, but we really did it. We graduated.
            </div>
    },
    {
        title: "Thoughts: Graduating With a 4.0",
        date: "07/01/2024",
        tags: ["Graduation"],
        content:
            <div>
                Available in: <a href={require("./Eng Thoughts Graduating With a 4.0.pdf")}>English</a>, 中文 (coming soon)， <a href={require("./Kor Thoughts Graduating With a 4.0.pdf")}>한국어</a>.
            </div>
    },

    {
        title: "What I Would've Said If I Were Commencement Speaker",
        date: "07/11/2024",
        tags: ["Graduation"],
        content:
            <div>
                It's September 28th. I must've started this early July. How did I put this off so long... It sort of feels like cheating to circle back to this now that I have a few months of post grad clarity. But, most of this was written in the few weeks after college and it's still a little piece that I think captures what I would've said at the time. <a href={require("./Reflections on College Life and Life After_ What I Would’ve Said If I Were Commencement Speaker.pdf")}>Enjoy</a>.
            </div>
    },
    {
        title: "Starting Work",
        date: "11/03/2024",
        tags: ["Work"],
        content:
            <div>
                I'm a bit over 2 months into work (actually, I sit on these posts for so long that it's almost 3 months now)! It's a good time to take a step back and reflect on my first couple of months being professional. But, I feel like I should point out that I really can't write my unfiltered thoughts here. For one, I'm too young to know what I should keep to myself in the workplace. And second, what I care more about, people at work will know exactly who I'm talking about. So let's focus on the good parts!
                <br/><br/>
                The beginning is always a little scary. There's the excitement of starting something new paired with the doubt of how things will turn out. That blend of nervous excitement feels inevitable when it comes time to try something for the first time. There's also lots of wondering. Are the people there nice? Does the office look cool? Will I make friends?
                <br/><br/>
                Luckily, I had it a bit easier than most when it comes to the first day. About 20 other new grads started the same day, so we had a nice little orientation. In a way, it felt like college. All of us sitting in a room, listening to talks - some interesting, some not so much. It's hitting us in that moment. It's finally time to dive into the adventure that all these years of school have led us to.
                <br/><br/>
                I quickly met my desk buddy, the person I would be sitting next to. Also a new grad, he grew up in the Bay and works in Clinical, just like me. I don't remember having many thoughts about him when it all started. Nice guy is all I could really say. But, now that we're comfortably settled in, I'm happy to realize that we actually get along quite well. Him and I have a daily tradition of scheduling a snack party (literally on our open calendars) where we go and chill near HQ's entrance to eat and talk. No matter how full or busy we are, we always find some time to go. I tell him that one day, 20 years down the line, these snack parties will be written in the company history books.
                <br/><br/>
                Looking back with a couple months worth of hindsight, I should say one of the things I took for granted at the beginning is how nice it is to have such a large new grad program. There's so many new grads at the office that when a couple friends visited, they couldn't believe   the young folks around were all employees. Someone said it looked like school.
                <br/><br/>
                With such an active new grad population, you're bound to find people you vibe with. And the best part is the company is so big, sometimes it doesn't even feel like you're working at the same place. I genuinely have no clue what some of my work friends work on all day. Who knows if they're actually working. Anyways, I'd definitely feel a bit more isolated if this new grad program weren't a thing. It's nice to have the space at work to have fun and not take things so seriously all the time. 
                <br/><br/>
                The parts of my job that I'm grateful for have shifted as time has gone on. At first, it was the free food, the game room, the ping pong tables - pretty much all the fun parts of the environment. Those were nice distractions from the monotony of training and onboarding. Now, I still love those parts. They're a part of my daily life. But I'm even more grateful for the office space (our desks are huge), the feeling of safety and belonging, and the opportunity to show up at a place filled with people that treat you with respect.
            </div>
    },
    {
        title: "November's End: A Brief Reflection",
        date: "11/24/2024",
        tags: ["Work"],
        content:
            <div>
                It's officially the first holiday season of my working life. To me, Thanksgiving week is as much a reminder of the festive season as it is a reminder of how quickly the end of the year is approaching. My family has never been one to take the holidays too seriously. Christmas trees, red and green lights, and deer decorations were things we enjoyed in the mid 40's breeze on our somewhat annual Christmas night neighborhood tour. These times always brought me a sense of peace. Families are together, people are happy, and, of course, school is out.
                <br/><br/>
                This year is a bit different. School is out, but it's been out since June. Really, <i>I've</i> been out since June. Life is changing. I can't wait to see how the future redefines what this time of year means to me.
            </div>
    },
    {
        title: "小红书",
        date: "01/14/2025",
        tags: ["Random"],
        content:
            <div>
                For many years I've wondered if China would have their cultural moment in the US. I honestly never thought I'd see it in my lifetime - not even a fraction of that sort of excitement people here have for Korean shows and Korean music. So when I opened 小红书 yesterday to see posts and comments in English about the "TikTok refugees", I couldn't believe it.
                <br/><br/>
                I'm not sure how I feel - strongly neutral might be a good way to put it. On the one hand, it's really strange to have the cultural immersion of 小红书 so suddenly fractured. It's also just really weird to see English on the platform. But on the other hand, it's exciting to be part of probably the first time in history random people in the US have been able to make meaningful connections with random people in China.
                <br/><br/>
                Genuine interest from both sides in the concept of what daily life looks like on the other side of the world gives me hope for mutual understanding, respect, and maybe even a bit of admiration. I think people will realize that these places are filled with people who aren't as different as they might've once thought.
                <br/><br/>
                And there's real motivation for non-Chinese speakers to learn Mandarin. This part is especially jarring having grown up in schools that didn't teach any Chinese language. I'm not used to hearing or seeing non Chinese people speak Mandarin, so it gives me a bit of happiness seeing this growing interest in what I consider to be a very beautiful language.
                <br/><br/>
                It's funny that a TikTok ban of all things is what brought us to this moment. And it feels so random. Why 小红书 of all places?!? Perhaps this is all a good reminder that things don't have to be the way they've always been.
                <br/><br/>
                P.S. I can't believe this is my first post of 2025 haha. I feel like it's just been such a long time since I've been so interested and surprised by a social movement.
            </div>
    },
]

export default blogposts;


/*
{
        title: "",
        date: "",
        tags: [],
        content:
            <div>
            </div>
    },

*/

/*
1) Seeing friends after college grad
2) Working at a coffee shop
3) CS Series
*/